<!--
TreatmentRateEditForm.vue
Description: form for adding/editing a single Treatment Rate.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <div class="md-layout-item md-small-size-100 md-size-100">
      <TreatmentsDropdown :class="getClass('selectedTreatmentType')" v-model="selectedTreatmentType"
        data-vv-name="selectedTreatmentType" :is_required="true" v-validate="modelValidations.selectedTreatmentType"
        @input='onTreatmentChange' required />

      <!--UnitsDropdown :class="getClass('fk_unit')" v-model="fk_unit" data-vv-name="fk_unit" 
        v-validate="modelValidations.fk_unit" :is_required="true" required  /-->
      </div>

      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="getClass('unit_quantity')">
            <label for="unit_quantity">{{ $t('condition.number_units') }}</label>
            <md-input id="unit_quantity" v-model.number="unit_quantity" type="number" data-vv-name="unit_quantity"
              v-validate="modelValidations.unit_quantity" :min="modelValidations.rate.min_value" required />
          </md-field>
        </div>

        <div class="md-layout-item md-small-size-100 md-size-50">
          <FloatInput :class="getClass('rate')" :label="$t('label.rate')" v-model="rate" data-vv-name="rate"
            v-validate="modelValidations.rate" :min="modelValidations.rate.min_value" required />
        </div>
      </div>
    </template>
  </modal>
</template>
<script>
  import { mapActions } from 'vuex'
  import { Modal, TreatmentsDropdown, UnitsDropdown, FloatInput } from '@/pages/Components'
  import permissions from "@/mixins/permissionsMixin"
  import messages from '@/mixins/messagesMixin'

  export default {
    name: 'treatment-rate-edit-form',
    mixins: [permissions, messages],

    data() {
      return {
        formName: 'TreatmentRateEditForm',
        eligible: false,

        selectedTreatmentType: 0,
        treatmentDescr: null,
        //fk_unit: 0,
        unit_quantity: 0,
        rate: 0,

        isLoading: false,
        initialFormState: null,

        modelValidations: {
          selectedTreatmentType: { required: true, numeric: true, min_value: 1 },
          rate: { required: true, decimal: true, min_value: 1 },
          unit_quantity: { required: true, numeric: true, min_value: 1 }
        }
      }
    },

    props: {
      itemId: null
    },

    components: {
      Modal,
      TreatmentsDropdown,
      FloatInput,
      //UnitsDropdown
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed()
      if (!this.eligible) {
        this.$emit('close')
        return
      };

      if (this.itemId) {
        // Load data on existing treatment rate
        const rate = await this.loadTreatmentRate(this.itemId)
        this.selectedTreatmentType = rate.fk_treatment_type
        this.unit_quantity = rate.unit_quantity
        //this.fk_unit = rate.fk_unit
        this.rate = rate.rate
      }

      this.$nextTick(() => {
        this.saveInitialState()
        this.$validator.validateAll()
      })
    },

    methods: {
      ...mapActions({
        loadTreatmentRate: 'LOAD_TREATMENT_RATE_BY_ID',
        addTreatmentRate: 'ADD_TREATMENT_RATE',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      onTreatmentChange(id, treatmentDescr) {
        this.treatmentDescr = treatmentDescr
      },

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const payload = {
          fk_treatment_type: this.selectedTreatmentType,
          unit_quantity: this.unit_quantity,
          rate: this.rate
        }

        let errDesc = '';
        let newId;
        try {
          const res = await this.addTreatmentRate(payload)
          newId = res?.treatment_rate_id;
        } catch (err) {
          errDesc = err.message || this.$t('messages.unknown_error')
        }

        this.$nextTick(() => this.$validator.reset())
        this.$emit('close')
        await this.savedMessage(errDesc, this.$t('route.treatment_rates'), this.treatmentDescr)
        this.highlightRow(newId)
      },

      saveInitialState() {
        this.initialFormState = {
          selectedTreatmentType: this.selectedTreatmentType,
          unit_quantity: this.unit_quantity,
          rate: this.rate
        }
      },
    },

    computed: {
      screenTitle() {
        return this.itemId ? this.$t('screen_titles.treatment_rate_upd') : this.$t('screen_titles.treatment_rate_add')
      },

      hasUnsavedChanges() {
        if (!this.initialFormState) return false

        return this.selectedTreatmentType !== this.initialFormState.selectedTreatmentType ||
          this.unit_quantity !== this.initialFormState.unit_quantity ||
          //this.fk_unit !== this.initialFormState.fk_unit ||
          this.rate !== this.initialFormState.rate
      }
    }
  }
</script>